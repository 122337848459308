import React from 'react'

function Header({whoamiData,logoff,loginStatus=false}) {
    return (
        <div className='position-fixed bg-white z-3 header-width'>
            <div className='d-flex align-items-center p-3'>
                <div className='logo'><img src='suvi_logo.jpg' /></div>
                <div className='header__text'>🚚 🛺 Transport Partner App</div>
       
            </div>
            {loginStatus &&
            <div className='d-flex px-3 pb-3 justify-content-between'>
                    <div className='text-name'>
                        Name:&nbsp;
                        {whoamiData?.owned_parties?.[0]?.name}
                    </div>
                    <div className=''>
                        <a style={{cursor:"pointer"}} className='text p-3 cursor-pointer primary text-decoration-none' onClick={logoff}>[ logoff ]</a>
                        {/* <button onClick={logoff} className='btn btn-primary text-end'>log out</button> */}
                    </div>
                </div>
}
        </div>
    )
}

export default Header
