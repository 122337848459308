import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from './axios'
import Request from '../request'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { InputGroup, Form } from "react-bootstrap"
import TableList from './TableList'
import Header from './Header'

function List({ whoamiData = [], setLogin }) {
    const [data, setData] = useState([])
    const [slip, setSlip] = useState("")
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(10)
    const [displayList, setDisplayList] = useState("")
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        fetchData()
    }, [page])
    useEffect(() => {
        const elem = document.getElementsByClassName("suviapp")[0]
        elem.style.height = "100%"
    }, [])
    const fetchData = async (pageData) => {
        const data1 = {
            q: slip,
            page: pageData ? pageData : page,
            rows: rows
        }
        const res = await postRequest(Request.transportVouchar, data1)

        if (data?.length == 0) {
            setData(res.data?.apiresponse?.record)
        } else {
            setData([...data, ...res.data?.apiresponse?.record])
        }
        setLoader(false)
    }
    useEffect(() => {
        const elem = document.getElementById("amount0")
        if (elem) {
            elem.focus()
        }
    }, [data])
    const logoff = async () => {
        const res = await getRequest(Request.logoff)
        if (res?.data?.apiresponse?.type == "OK") {
            navigate("/")
            setLogin(true)
            toast.success(res?.data?.apiresponse?.message)
        } else {
            toast.error("Error in log out")
        }
    }
    const searchBtn = () => {
        setData([])
        setDisplayList(Math.random())
    }
    useEffect(() => {
        if (displayList) {
            fetchData(1)
        }
    }, [displayList])
    console.log("data", data)
    const moreBtn = () => {
        setPage(page + 1)
        setLoader(true)
    }
    return (
        <div>
            <Header whoamiData={whoamiData} logoff={logoff} loginStatus={true} />
            <div className='px-3 pt-75'>
                <ToastContainer />

                <div>
                    <div>

                        <InputGroup className="mb-3">
                            {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                            <Form.Control
                                placeholder="Enter Invoice or Challan No"
                                aria-label="Enter Invoice or Challan No"
                                aria-describedby="basic-addon1"
                                value={slip}
                                onChange={(e) => setSlip(e.target.value)}
                            />
                        </InputGroup>
                        <button className='btn btn-primary w-100 text-uppercase' onClick={searchBtn}>Search</button>
                    </div>
                </div>
                <div className='pb-4'>
                    <TableList data={data} />
                    <button className='btn btn-secondary w-100' onClick={moreBtn}>
                        {loader ? <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div> : "More"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default List