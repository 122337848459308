const base_url = "https://suvidash.eezyerp.in/"
const Request = {
  send_otp: base_url + "account/send_otp",
  verify_otp: base_url + "account/login_with_mobile_otp",
  whoami: base_url + "account/whoami",
  logoff: base_url + "account/logoff",
  transportVouchar: base_url + "transporter/transporter_voucher",
  verifyTransporter: base_url + "transporter/verified_by_transporter",
  claimTransporter: base_url + "transporter/transporter_amount_claim"
}
export default Request