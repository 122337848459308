import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import moment from 'moment/moment'
function Table({ value, checkBtn, index, remainingTime }) {
    const [amount, setAmount] = useState(value?.amount_claimed)

    return (
        <tr>
            <th scope="row">{moment(value?.voucher_date).format('ll')}</th>
            <td><span className='fw-bold'>{value?.party_name ? value?.party_name : "N.A"}</span><br /><span className='fw-bold'>Inv No:-</span>{value?.voucher_number ? value?.voucher_number : "N.A"}<br /><span className='fw-bold'>Ch No:-</span>{value?.challan_no ? value?.challan_no : "N.A"}</td>
            <td>{value?.place_from ? value?.place_from : "N.A"}<br />{value?.place_to ? value?.place_to : "N.A"}</td>
            <td className='text-center'>
                {/* {value?.total_amount ? value?.total_amount : "N.A"} */}
                <div>
                    <input id={`amount${index}`} disabled={value.allow_transport_edit == 0 ? true : false} value={amount} onChange={(e) => setAmount(e.target.value)} type='text' className='amount_box' />
                    <br />
                    {value?.verified_by_transporter == 0 ?
                        <button className='btn btn-primary claim-btn m-2'
                            onClick={() => checkBtn(value.voucher_id, amount)}
                        >Check</button> :
                        <button disabled className='btn btn-success claim-btn m-2 d-flex'>Verified<img src='check.svg' /></button>}
                </div>
                {/* <small> {remainingTime(value?.amount_claimed_on)}</small> */}
                {(value?.verified_by_transporter == 0 && value?.amount_claimed) &&
                    <Countdown date={value?.amount_claimed_on * 1000 + 172800000} />}

            </td>
        </tr>
    )
}

export default Table