import React, { useEffect, useState } from 'react'
import { InputGroup, Form } from 'react-bootstrap'
import { getRequest, postRequest } from './axios'
import Request from '../request'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Header from './Header'
function Login({ setLogin, whoami }) {
    const [mobile, setMobile] = useState("")
    const [otp, setOtp] = useState("")
    const navigate = useNavigate()
    const sendBtn = async (e) => {
        e.preventDefault()
        const data = {
            reveal: 1,
            contact_number: mobile
        }
        const res = await postRequest(Request.send_otp, data)
        if (res?.data?.apiresponse?.type == "OK") {
            console.log(res?.data?.apiresponse?.message)
            let name = (res?.data?.apiresponse?.message).split("(")[1]?.replace(")", "")
            // setOtp(name)
            toast.success(res?.data?.apiresponse?.message)
        } else {
            toast.error(res?.data?.apiresponse?.message)
        }
        console.log("res", res)
    }
    const verifyBtn = async () => {
        const data = {
            otp: otp
        }
        const res = await postRequest(Request.verify_otp, data)
        if (res.data?.apiresponse?.type == "OK") {
            toast.success(res.data?.apiresponse?.message)
            navigate("/list")
            whoami()
            setLogin(false)
        } else {
            toast.error(res.data?.apiresponse?.message)
        }
    }

    return (
        <>
            <Header />
            <div className='px-3  pt-75'>
                <div className=''>
                    <ToastContainer />
                    <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                        <Form.Control
                            placeholder="Enter Mobile Number"
                            aria-label="Enter Mobile Number"
                            aria-describedby="basic-addon1"
                            type='number'
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                    </InputGroup>
                    <button className='btn btn-primary w-100 text-uppercase' onClick={sendBtn}>Send Otp</button>
                </div>
                <div className='pt-3'>
                    <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                        <Form.Control
                            placeholder="Enter Otp"
                            aria-label="Enter Otp"
                            aria-describedby="basic-addon1"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </InputGroup>
                    <button className='btn btn-success w-100 text-uppercase' onClick={verifyBtn}>Verify Otp</button>
                </div>
                <div>

                </div>
            </div>
        </>
    )
}

export default Login